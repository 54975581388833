<template>
    <div>
        <b-card
            no-body
            class="mb-2"
            >
                <b-container fluid>
                    <b-row class="p-1">
                        <b-col cols="12" md="12">
                            <b-card class="mb-0">
                                <b-card-title>
                                    <h4 class="m-0 text-right">{{ this.id }}</h4>
                                </b-card-title>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-card class="border" title="Farmacia">
                                            <b-form-group>
                                                <label for=""><b>Farmacia</b></label>
                                                <span class="d-block">{{ delivery.customer.name }}</span>
                                            </b-form-group>
                                            <b-form-group>
                                                <label for=""><b>Télefono</b></label>
                                                <span class="d-block">{{ delivery.customer.phone }}</span>
                                            </b-form-group>
                                            <b-form-group>
                                                <label for=""><b>Celular</b></label>
                                                <span class="d-block">{{ delivery.customer.mobile }}</span>
                                            </b-form-group>
                                            <b-form-group>
                                                <label for=""><b>Correo</b></label>
                                                <span class="d-block">{{ delivery.customer.email }}</span>
                                            </b-form-group>
                                        </b-card> 
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <b-card class="border" title="Solicitante">
                                            <b-form-group>
                                                <label for=""><b>Nombre</b></label>
                                                <span class="d-block">{{ delivery.person.names }}</span>
                                            </b-form-group>
                                            <b-form-group>
                                                <label for=""><b>Apellido</b></label>
                                                <span class="d-block">{{ delivery.person.surnames }}</span>
                                            </b-form-group>
                                            <b-form-group>
                                                <label for=""><b>Teléfono</b></label>
                                                <span class="d-block">{{ delivery.person.phone }}</span>
                                            </b-form-group>
                                            <b-form-group>
                                                <label for=""><b>Correo</b></label>
                                                <span class="d-block">{{ delivery.person.email }}</span>
                                            </b-form-group>
                                        </b-card>                             
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-card class="border" title="Detalle de la entrega">
                                            <b-row>
                                                <b-col cols="12" md="6">
                                                    <b-form-group>
                                                        <label for=""><b>Fecha de creación</b></label>
                                                        <span class="d-block">{{ delivery.created_at }}</span>
                                                    </b-form-group>
                                                    <b-form-group>
                                                        <label for=""><b>Fecha actualización</b></label>
                                                        <span class="d-block">{{ delivery.updated_at }}</span>
                                                    </b-form-group>
                                                    <b-form-group>
                                                        <label for=""><b>Precio</b></label>
                                                        <span class="d-block">$ {{ delivery.price }}</span>
                                                    </b-form-group>
                                                    <b-form-group>
                                                        <label for=""><b>Estado</b></label>
                                                        <span class="d-block">{{ delivery.status.toUpperCase() }}</span>
                                                    </b-form-group>
                                                    <b-form-group>
                                                        <label for=""><b>Municipio</b></label>
                                                        <span class="d-block">{{ delivery.municipality }}</span>
                                                    </b-form-group>
                                                    <b-form-group>
                                                        <label for=""><b>Dirección</b></label>
                                                        <span class="d-block">{{ delivery.address }}</span>
                                                    </b-form-group>
                                                    <b-form-group>
                                                        <label for=""><b>Barrio</b></label>
                                                        <span class="d-block">{{ delivery.neighborhood }}</span>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12" md="6">
                                                    <p><b>Productos</b></p>
                                                    <b-skeleton-table :rows="1" :columns="2"></b-skeleton-table>
                                                    <p><b>Formulas médicas</b></p>
                                                    <b-skeleton-table :rows="1" :columns="2"></b-skeleton-table>
                                                    <p><b>Autorizaciones</b></p>
                                                    <b-skeleton-table :rows="1" :columns="2"></b-skeleton-table>
                                                </b-col>
                                            </b-row>            
                                        </b-card>
                                    </b-col>
                                </b-row>
                                <!-- This is temporal -->
                                <b-row>
                                    <b-col cols="12" md="12">
                                        <b-card class="border" title="Seguimiento">
                                            <iframe
                                                width="100%"
                                                height="450"
                                                frameborder="0" style="border:0"
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d254508.51141489705!2d-74.107807!3d4.64829755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f9bfd2da6cb29%3A0x239d635520a33914!2zQm9nb3TDoQ!5e0!3m2!1ses-419!2sco!4v1698010390513!5m2!1ses-419!2sco" allowfullscreen>
                                            </iframe>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-container>
        </b-card>
    </div>
 </template>

<script>

export default {
    name: 'DeliveryDetailPublic',
    data() {
        return {
            id: this.$route.params.id,
            delivery: null,
        }
    },
    methods: {
        async getDelivery() {
            try {
                const {data} = await this.$http.get(`deliveries/v/${this.id}`)
                this.delivery = data
            } catch (error) {
                console.error(error)
            }
        },
    },
    created() {
        this.getDelivery()
    },
} 
</script>